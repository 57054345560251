(function ($) {
  var $giftCard = $(".hikashop_characteristic_line_40");

  $giftCard.find(".inputbox:checked").parent().addClass("active");

  $giftCard.find("label").on("click", function () {
    $giftCard.find("label").removeClass("active");
    $(this).addClass("active");
  });
})(jQuery);
