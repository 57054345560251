import "../sass/main.scss";

import "./navigation";
import "./home";
import "./utils";
import "./form";
import "./administration";
import "./menu_category";
import "./product";

import SmoothScroll from "smooth-scroll";

jQuery(document).ready(function ($) {
  // ================================================== LOADER
  $(window).load(function () {
    $("#status").fadeOut();
    $("#preloader").delay(350).fadeOut(500);
    $("body").delay(350).css({
      overflow: "visible",
    });
  });
  // ================================================== CLOSE ALERT
  $(".close").click(function () {
    $(".alert").hide();
  });

  // ================================================== SMOOTH
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500,
    speedAsDuration: true,
  });
});
